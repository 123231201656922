import React, {useEffect, useRef, useState} from "react"
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Jumbotron, Label,
  ListGroup,
  ListGroupItem,
  Row, Spinner
} from 'reactstrap'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {FaBus, FaExchangeAlt, FaRoute, FaWalking} from 'react-icons/fa'
import {graphql} from "gatsby";

import 'leaflet/dist/leaflet.css'
import {TileLayer, Marker, Popup, Polyline} from 'react-leaflet'
import polyUtil from 'polyline-encoded';

import {LangContext} from "../components/lurraldebusWrapper";
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from '../components/pageTitle'
import Map from "../components/map"

import MapMarkerParadaIcon from "../components/mapMarkerParadaIcon"
import MapMarkerCurrentIcon from "../components/mapMarkerCurrentIcon"

import kbusBanner from '../assets/images/banner/banner_home.png';

const CalculaTuRutaPage = ({data}) => {

  const [direccionQueryOrigen, setDireccionQueryOrigen] = useState("");
  const [candidatosOrigen, setCandidatosOrigen] = useState(null);
  const [direccionQueryDestino, setDireccionQueryDestino] = useState("");
  const [candidatosDestino, setCandidatosDestino] = useState(null);
  const [origenSeleccionado, setOrigenSeleccionado] = useState(null);
  const [destinoSeleccionado, setDestinoSeleccionado] = useState(null);
  const [route, setRoute] = useState(null);
  const [tiempoRuta, setTiempoRuta] = useState(null);
  const [mapBounds, setMapBounds] = useState([]);

  const [processingRuta, setProcessingRuta] = useState(false);
  const [processingOrigen, setProcessingOrigen] = useState(false);
  const [processingDestino, setProcessingDestino] = useState(false);

  const inputOrigenRef = useRef(null)
  const inputDestinoRef = useRef(null)

  const redOptions = { color: '#f69f06', smoothFactor: 0.1 }
  const greenOptions = { color: '#129540', smoothFactor: 0.1 }

  useEffect(() => {
    if (origenSeleccionado !== null && inputOrigenRef !== null) {
      inputOrigenRef.current.value = origenSeleccionado.place_name.split(",").slice(0,2).join(",")
      setCandidatosOrigen(null);
    }
  }, [origenSeleccionado])

  useEffect(() => {
    if (destinoSeleccionado !== null && inputDestinoRef !== null) {
      inputDestinoRef.current.value = destinoSeleccionado.place_name.split(",").slice(0,2).join(",")
      setCandidatosDestino(null);
    }
  }, [destinoSeleccionado])

  useEffect(() => {
    const _bounds = [];
    if (origenSeleccionado !== null) {
      _bounds.push(origenSeleccionado.center.slice().reverse())
    }

    if (destinoSeleccionado !== null) {
      _bounds.push(destinoSeleccionado.center.slice().reverse());
    }
    setMapBounds(_bounds);

    if (destinoSeleccionado !== null && origenSeleccionado !== null) {
      buscarRuta()
    }
  }, [origenSeleccionado, destinoSeleccionado]);

  const resetRuta = () => {
    setOrigenSeleccionado(null);
    setDestinoSeleccionado(null);
    setRoute(null);
  }

  const buscarDireccion = (direccion, setter, setProcessing) => {
    const xhr = new XMLHttpRequest()
    xhr.open("GET", `https://buruntzaldeabus.eus/getGeoCandidatesv2.php?q=${direccion}`)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      } else {
        setProcessing(false)
      }
      if (xhr.status === 200) {
        try {
          const candidatesRaw = JSON.parse(xhr.responseText)
          const candidates = Object.values(candidatesRaw).filter((candidate) => {
            return true;
          })
          setter(candidates);
        } catch (e) {
          console.log(e);
        }
      } else {
        // TODO handle error
      }
    };
    setProcessing(true)
    xhr.send()
  }

  const buscarRuta = () => {
    const xhr = new XMLHttpRequest()
    const o = origenSeleccionado.center.slice().reverse().join(",");
    const d = destinoSeleccionado.center.slice().reverse().join(",");
    xhr.open("GET", `https://buruntzaldeabus.eus/planificador/getRoute.php?o=${o}&d=${d}`)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      } else {
        setProcessingRuta(false);
      }
      if (xhr.status === 200) {
        try {
          const _route = JSON.parse(xhr.responseText)
          let latLngBounds = [];
          _route.forEach((step) => {
            if (step.ruta !== null) {
              const ruta = polyUtil.decode(step.ruta);
              latLngBounds = latLngBounds.concat(ruta);
            }
          });
          setMapBounds(latLngBounds);
          setRoute(_route);
          const tiempoRuta = _route.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.tiempo;
          }, 0);
          setTiempoRuta(tiempoRuta);
        } catch (e) {
          console.log(e);
        }
      } else {
        // TODO handle error
      }
    };
    setProcessingRuta(true);
    xhr.send()
  }

  return (
    <LangContext.Consumer>
      { context => (
        <Layout>
          <SEO title={context.translate(data, 'calculaTuRuta', 'titulo')} />

          <Container className="pt-5 mb-5">
            <PageTitle title={context.translate(data, 'calculaTuRuta', 'titulo')}/>
            <Row className={"py-3"}>
              <Col xs={12} lg={{size:8, offset:2}} xl={{size: 6, offset:3}}>
                <Label>{context.translate(data, 'calculaTuRuta', 'puntoDePartida')}</Label>
                <InputGroup>
                  <Input innerRef={inputOrigenRef} placeholder={context.translate(data, 'calculaTuRuta', 'placeholderPuntoPartida')}
                         onChange={(e) => {setDireccionQueryOrigen(e.target.value)}}
                         onKeyPress={(e) => {if (e.charCode === 13) {setOrigenSeleccionado(null); buscarDireccion(direccionQueryOrigen, setCandidatosOrigen, setProcessingOrigen)}}}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="danger" disabled={direccionQueryOrigen.length < 4 || processingOrigen}
                            onClick={() => {setOrigenSeleccionado(null); buscarDireccion(direccionQueryOrigen, setCandidatosOrigen, setProcessingOrigen)}}>
                      {context.translate(data, 'calculaTuRuta', 'buscar')}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                <div className={"kbus-animation-1 mt-3"} style={
                  {
                    maxHeight: `${candidatosOrigen !== null ? '1000px' : '0px'}`,
                    opacity: `${candidatosOrigen !== null > 0 ? '1' : '0'}`,
                    overflow: 'hidden'
                  }
                }>
                  <Label>{context.translate(data, 'calculaTuRuta', 'resultados')}:</Label>
                  {candidatosOrigen !== null && candidatosOrigen.length > 0 && (
                    <ListGroup>
                    {candidatosOrigen.map((candidato, index) => {
                      const selected = origenSeleccionado !== null ? candidato.id === origenSeleccionado.id : false;
                      return (
                        <ListGroupItem key={index}
                                       className={`${selected ? 'kbus-parada-selected' : ''} kbus-resultados-ruta d-flex flex-row justify-content-between align-items-center py-1 px-3`}
                                       onClick={() => {setOrigenSeleccionado(candidato)}}
                        >
                          <span className="kbus-parada-linea-font">{candidato.place_name.split(",")[0]}</span>
                          <div className="d-flex flex-column align-items-end">
                            <span className="kbus-parada-linea-font py-0 text-capitalize">{candidato.place_name.split(",")[1].split(" ").slice(2).join(" ").toLowerCase()}</span>
                            <span className={`${selected ? '' : 'text-muted'} kbus-parada-linea-sub-font py-0`}>{candidato.place_name.split(",")[1].split(" ")[1]}</span>
                          </div>
                        </ListGroupItem>
                      )
                    })}
                    </ListGroup>
                  )}
                  {candidatosOrigen !== null && candidatosOrigen.length === 0 && (
                    <div className={"d-flex justify-content-center align-items-center"}>
                      <span className={"text-muted text-center"}>{context.translate(data, 'calculaTuRuta', 'noResultados')}</span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <Row className={"py-3"}>
              <Col xs={12} lg={{size:8, offset:2}} xl={{size: 6, offset:3}}>
                <Label>{context.translate(data, 'calculaTuRuta', 'puntoDeDestino')}</Label>
                <InputGroup>
                  <Input innerRef={inputDestinoRef} placeholder={context.translate(data, 'calculaTuRuta', 'placeholderPuntoPartida')}
                         onChange={(e) => {setDireccionQueryDestino(e.target.value)}}
                         onKeyPress={(e) => {if (e.charCode === 13) {setDestinoSeleccionado(null); buscarDireccion(direccionQueryDestino, setCandidatosDestino, setProcessingDestino)}}}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="danger" disabled={direccionQueryDestino.length < 4 || processingDestino}
                            onClick={() => { setDestinoSeleccionado(null); buscarDireccion(direccionQueryDestino, setCandidatosDestino, setProcessingDestino)}}>
                      {context.translate(data, 'calculaTuRuta', 'buscar')}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                <div className={"kbus-animation-1 mt-3"} style={
                  {
                    maxHeight: `${candidatosDestino !== null ? '1000px' : '0px'}`,
                    opacity: `${candidatosDestino !== null ? '1' : '0'}`,
                    overflow: 'hidden'
                  }
                }>
                  <Label>{context.translate(data, 'calculaTuRuta', 'resultados')}:</Label>
                  {candidatosDestino !== null && candidatosDestino.length > 0 && (
                    <ListGroup>
                      {candidatosDestino.map((candidato, index) => {
                        const selected = destinoSeleccionado !== null ? candidato.id === destinoSeleccionado.id : false;
                        return (
                          <ListGroupItem key={index}
                                         className={`${selected ? 'kbus-parada-selected' : ''} kbus-resultados-ruta d-flex flex-row justify-content-between align-items-center py-1 px-3`}
                                         onClick={() => {setDestinoSeleccionado(candidato)}}
                          >
                            <span className="kbus-parada-linea-font">{candidato.place_name.split(",")[0]}</span>
                            <div className="d-flex flex-column align-items-end">
                              <span className="kbus-parada-linea-font py-0 text-capitalize">{candidato.place_name.split(",")[1].split(" ").slice(2).join(" ").toLowerCase()}</span>
                              <span className={`${selected ? '' : 'text-muted'} kbus-parada-linea-sub-font py-0`}>{candidato.place_name.split(",")[1].split(" ")[1]}</span>
                            </div>
                          </ListGroupItem>
                        )
                      })}
                    </ListGroup>
                  )}
                  {candidatosDestino !== null && candidatosDestino.length === 0 && (
                    <div className={"d-flex justify-content-center align-items-center"}>
                      <span className={"text-muted text-center"}>{context.translate(data, 'calculaTuRuta', 'noResultados')}</span>
                    </div>
                  )}
                </div>

              </Col>
            </Row>

            <Row className={"py-3"}>
              <Col xs={12} lg={{size:8, offset:2}} xl={{size: 6, offset:3}} className="d-flex justify-content-center align-items-center">
                {processingRuta && <Spinner color={"danger"}/>}
              </Col>
            </Row>
            {/*
          </Container>

          <Container className={"pt-5 mb-5"}>
            <PageTitle title={"Calcula tu ruta"}/>
            <Row className={"py-3"}>
              <Col xs={12} className={"d-flex justify-content-center align-items-center"}>
                <a className="text-muted" onClick={() => {resetRuta()}}>Calcular nueva ruta</a>
              </Col>
            </Row>
            */}
            <Row className={"py-3"}>
              <Col xs={12} style={{height: '500px'}}>
                <Map centroMapa={[43.305472385100934,-2.982078448595903]} zoomMapa={13} styleMapa={{height: '100%'}} mapaBounds={mapBounds}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {route && route
                    .filter((step) => step.ruta !== null).map((step, index) => {
                    const ruta = polyUtil.decode(step.ruta);
                    return <Polyline key={index} pathOptions={step.conexion === "bus" ? redOptions : greenOptions} positions={ruta} />
                  })}

                  {route && route
                    .filter((step) => step.conexion === "bus").map((step, index) => {
                      return (
                        <Marker key={index} position={step.origen.coord} icon={MapMarkerParadaIcon()}>
                          <Popup>
                            <span className="font-weight-bold">{step.origen.nombre}</span>
                            <br/>
                            <span>{step.origen.linea}</span>
                          </Popup>
                        </Marker>
                      )
                  })}
                  {route && route
                    .filter((step) => step.conexion === "bus").map((step, index) => {
                      return (
                        <Marker key={index} position={step.destino.coord} icon={MapMarkerParadaIcon()}>
                          <Popup>
                            <span className="font-weight-bold">{step.destino.nombre}</span>
                            <br/>
                            <span>{step.destino.linea}</span>
                          </Popup>
                        </Marker>
                      )
                    })}
                  {origenSeleccionado && (
                    <Marker position={origenSeleccionado.center.slice().reverse()} icon={MapMarkerCurrentIcon()}>
                      <Popup>
                        <span className="font-weight-bold">{context.translate(data, 'calculaTuRuta', 'puntoDePartida')}</span>
                      </Popup>
                    </Marker>
                  )}

                  {destinoSeleccionado && (
                    <Marker position={destinoSeleccionado.center.slice().reverse()} icon={MapMarkerCurrentIcon()}>
                      <Popup>
                        <span className="font-weight-bold">{context.translate(data, 'calculaTuRuta', 'puntoDeDestinon')}</span>
                      </Popup>
                    </Marker>
                  )}
                </Map>
              </Col>
            </Row>

            {route && (
              <Row className={"pt-5"}>
                <Col xs={{size: 12, offset:0}} md={{size:8, offset:2}} lg={{size:6, offset:3}}>
                  <h4 className="text-center font-weight-bold border-bottom pb-3"><FaRoute className="mb-1 mr-2"/>{context.translate(data, 'calculaTuRuta', 'itinerario')}</h4>
                </Col>
              </Row>
            )}
            {route && false && (
              <Row className={"py-3"}>
                <Col xs={{size: 12, offset:0}} md={{size:8, offset:2}} lg={{size:6, offset:3}}>
                  <p className={"text-center text-muted"}>{context.translate(data, 'calculaTuRuta', 'tiempoAproximado')}: {tiempoRuta} {context.translate(data, 'calculaTuRuta', 'minutos')}</p>
                </Col>
              </Row>
            )}
            {route && (
              <Row className={"py-3"}>
                <Col xs={{size: 12, offset:0}} md={{size:8, offset:2}} lg={{size:6, offset:3}}>
                  <VerticalTimeline animate={false} layout={'1-column-left'}>
                    {route.map((step, index) => {
                      let stepIcon = <FaBus/>
                      let stepColor = "#f69f06";
                      let iconColor = "#f69f06";
                      let stepTitle = "";
                      let stepSubtitle = "";
                      let stepContent = "";
                      const nombreLinea = step.linea !== null ? step.linea.split("||")[0].toLowerCase() : "N/A";
                      const direccionLinea = step.linea !== null ? step.linea.split("||")[1].toLowerCase() : "N/A";

                      switch(step.conexion) {
                        case "bus":
                          stepIcon = <FaBus/>
                          stepTitle = context.activeLang === 'eus'
                            ? <span>Hartu <strong className={"text-capitalize"}>{nombreLinea}</strong> linea</span>
                            : <span>Toma la línea <strong className={"text-capitalize"}>{nombreLinea}</strong></span>
                          stepSubtitle = <span>{context.translate(data, 'calculaTuRuta', 'direccion')} <strong className={"text-capitalize"}>{direccionLinea}</strong></span>
                          stepContent = (
                            <div className={"d-flex flex-column justify-content-center align-items-start pt-2"}>
                              <span>{context.translate(data, 'calculaTuRuta', 'hasta')} <strong>{step.destino.nombre}</strong></span>
                              <span><strong>{step.paradas.length > 0 ? step.paradas.length : "1"}</strong> {context.translate(data, 'calculaTuRuta', 'paradas')}:</span>
                              <ul>
                                {step.paradas.length > 0 ? step.paradas.map((parada) => (
                                  <li>{parada}</li>
                                )) : (<li>{step.destino.nombre}</li>)}
                              </ul>
                            </div>
                          )
                          break;
                        case "andando":
                          stepIcon = <FaWalking/>
                          stepTitle = <span>{context.translate(data, 'calculaTuRuta', 'dirigiteA')} <strong>{index === route.length -1 ? context.translate(data, 'calculaTuRuta', 'puntoDeDestino') : step.destino.nombre}</strong></span>
                          stepSubtitle = <span><strong>{Math.floor(step.distancia)}</strong> {context.translate(data, 'calculaTuRuta', 'metros')}</span>;
                          stepContent = (
                            <div className={"d-flex flex-column justify-content-center align-items-start pt-2"}>
                              <span>De: {index === 0 ? context.translate(data, 'calculaTuRuta', 'puntoDePartida') : step.origen.nombre}</span>
                              <span>A: {index === route.length -1 ? context.translate(data, 'calculaTuRuta', 'puntoDeDestino') : step.destino.nombre}</span>
                            </div>
                          )
                          break;
                        case "transbordo":
                          stepIcon = <FaExchangeAlt/>
                          stepTitle = <span>{context.translate(data, 'calculaTuRuta', 'transbordo')}</span>
                          stepSubtitle = null;
                          const siguienteLinea = route[index+1].linea !== null ? route[index+1].linea.split("||")[0].toLowerCase() : "N/A";
                          stepContent = (
                            <div className={"d-flex flex-column justify-content-center align-items-start pt-2"}>
                              {
                                context.activeLang === "eus"
                                  ? (<span><strong>{step.origen.nombre}</strong> geltokian, honako linea hauen aldaketa egin: </span>)
                                  : (<span>En la parada <strong>{step.origen.nombre}</strong> realiza el transbordo de las líneas: </span>)
                              }
                              <ul>
                                <li className={"text-capitalize"}>{nombreLinea}</li>
                                <li className={"text-capitalize"}>{siguienteLinea}</li>
                              </ul>

                            </div>
                          )
                          break;
                      }
                      return(
                        <VerticalTimelineElement
                          key={index}
                          contentStyle={{ border: `3px solid ${stepColor}`, boxShadow: 'none', marginBottom: '-1em' }}
                          contentArrowStyle={{ borderRight: `7px solid ${stepColor}` }}
                          iconStyle={{ background: iconColor, color: '#fff', boxShadow: 'none' }}
                          icon={stepIcon}
                          date={''}
                        >
                          <span style={{fontSize: '18px'}} className="vertical-timeline-element-title">{stepTitle}</span>
                          {stepSubtitle && (<p>{stepSubtitle}</p>)}
                          {stepContent}
                        </VerticalTimelineElement>
                      )
                    })}
                  </VerticalTimeline>
                </Col>
              </Row>
            )}
          </Container>

        </Layout>
      )}
    </LangContext.Consumer>
  )
}

export default CalculaTuRutaPage

export const pageQuery = graphql`  
  query {
    calculaTuRuta: strapiCalculaTuRuta {
      direccion
      calculaNuevaRuta
      dirigeteA
      metros
      minuto
      minutos
      noResultados
      paradas
      placeholderPuntoDestino
      placeholderPuntoPartida
      puntoDeDestino
      puntoDePartida
      resultados
      tiempoAproximado
      titulo
      transbordo
      itinerario
      hasta
      buscar
    }
    calculaTuRuta_eus: strapiCalculaTuRutaEus {
      direccion
      calculaNuevaRuta
      dirigeteA
      metros
      minuto
      minutos
      noResultados
      paradas
      placeholderPuntoDestino
      placeholderPuntoPartida
      puntoDeDestino
      puntoDePartida
      resultados
      tiempoAproximado
      titulo
      transbordo
      itinerario
      hasta
      buscar
    }
    lineas: allStrapiLineas {
      edges {
        node {
          label
          nombre
          rutas {
            direccion
            nombre
            paradas {
              nombre
              label
              coord {
                lat
                lon
              }
            }
          }
        }
      }
    }
  }
`
